import React from "react";

const AddressClearfield = () => {
  return (
    <>
      <h3>
        Clearfield County PA CareerLink<sup>&reg;</sup>
      </h3>
      <div>
        <p>
          <b>Clearfield</b>
          <br />
          1125 Linden Street
          <br />
          Clearfield, PA 16830
        </p>
        <p>
          Phone:
          <span>1-844-PACALINK</span>
        </p>
      </div>
      <div>
        <p>
          <b>DuBois</b>
          <br />
          602 West Dubois Avenue, Unit #1
          <br />
          DuBois, PA 15801
        </p>
        <p>
          Phone:
          <span>1-844-PACALINK</span>
        </p>
      </div>
    </>
  );
};

export default AddressClearfield;
