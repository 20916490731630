export const airports = [
  {
    id: 1,
    name: "DuBois Regional Airport - DUJ",
    city: "DuBois",
    url: "http://www.duboisairport.com/",
    counties: ["Clearfield", "Jefferson"],
  },
  {
    id: 2,
    name: "Clearfield-Lawrence Airport - FIG",
    city: "Clearfield",
    url: "http://www.clearfieldlawrence.com/",
    counties: ["Clearfield"],
  },
  {
    id: 3,
    name: "St Marys Municipal Airport - OYM",
    city: "St. Marys",
    url: "http://localhost:8000/counties/elk",
    counties: ["Elk"],
  },
  {
    id: 4,
    name: "Punxsutawney Municipal Airport - N35",
    city: "Punxsutawney",
    url: "http://www.punxsutawneyairport.com/",
    counties: ["Jefferson"],
  },
  {
    id: 5,
    name: "Bradford Regional Airport - BFD",
    city: "Lewis Run",
    url: "http://www.bradfordairport.net/",
    counties: ["McKean"],
  },
];
