import React from "react";
import { Link } from "gatsby";
import StatBox from "../../components/stat-box";
import PagePhoto from "../../components/page-photo";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import HeroPage from "../../components/hero-page";
import AddressClearfield from "../../components/address-clearfield";
import StatBoxes from "../../components/stat-boxes";
import ServiceList from "../../components/service-list";
import { postSecondary } from "../../data/post-secondary";
import { education } from "../../data/education";
import { hospitals } from "../../data/hospitals";
import { chambers } from "../../data/chambers";
import { airports } from "../../data/airports";
import Seo from "../../components/seo";

import ExplorePhotos from "../../components/explore-photos";

const Clearfield = ({ data }) => {
  const photos = data.allMdx.nodes;

  return (
    <>
      <Seo
        title="Clearfield County in North Central Pennsylvania"
        description="In Clearfield County, PA, individuals and their families can easily access destinations throughout the North Central PA region while exploring its backwoods, backroads and backwaters."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Clearfield County"
        caption="Slide Riding in Clearfield County"
        captionColor="black"
        position="60%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>
              Conveniently located in the center of Pennsylvania, along
              Interstate-80
            </h2>

            <p>
              In Clearfield County, individuals and their families can easily
              access destinations throughout the North Central PA region while
              exploring its backwoods, backroads and backwaters.
            </p>
          </div>

          <div className="intro-text">
            <p>
              Clearfield County was created in 1804 and named for Clearfield
              Creek. The name alluded to openings or “clear-fields” made by the
              large number of bison in the area.
              <br />
              Clearfield, the county seat, was incorporated as a borough in
              1840.
            </p>

            <p>
              Recent additions to the Clearfield County business community
              include the Wal-Mart Distribution Center, and the
              newly-constructed Clearfield Campus of Lock Haven University.
              Economic development opportunities also exist at the new DuBois
              Industrial Park.
            </p>
          </div>
        </section>

        <StatBoxes>
          <StatBox number="80,216" text="Total population" />
          <StatBox number="$44,421" text="Per capita income" />
          <StatBox number="DuBois" text="Largest city" />
        </StatBoxes>
      </main>

      <div className="county-employers">
        <section>
          <div className="employers-table-container">
            <h2>Top employers in Clearfield County</h2>

            <table className="employers">
              <tr>
                <th>Employer</th>
                <th>Industry</th>
              </tr>

              <tr>
                <td>Penn Highlands DuBois</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Wal-Mart Associates Inc</td>
                <td>Retail</td>
              </tr>

              <tr>
                <td>State Government</td>
                <td></td>
              </tr>

              <tr>
                <td>Cen-Clear Child Services Inc</td>
                <td></td>
              </tr>

              <tr>
                <td>Dubois Area School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>Paris Cleaners Inc</td>
                <td>
                  <Link to="/work/business-services">Community Services</Link>
                </td>
              </tr>

              <tr>
                <td>Christ the King Manor</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Clearfield Area School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>Clearfield Hospital</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Fayette Resources Incorporated</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>
            </table>
          </div>

          <div className="employers-photos">
            <PagePhoto
              image={data.employersImage1.sharp.image}
              caption="Advanced Powder Products, Clearfield County"
            />

            <PagePhoto
              image={data.employersImage2.sharp.image}
              caption="Christ the King Manor, Clearfield County"
            />
          </div>
        </section>
      </div>

      <main>
        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="Advanced Powder Products, Clearfield County"
          address=<AddressClearfield />
        />

        <section>
          <h2>County Services</h2>

          <ServiceList
            title="Education"
            image={data.educationImage.sharp.fluid}
            position="40%"
            county="Clearfield"
            data={education}
            cols={2}
            headingText="School"
          />

          <ServiceList
            title="Post Secondary Education"
            image={data.postSecondary.sharp.fluid}
            position="42%"
            county="Clearfield"
            data={postSecondary}
            cols={2}
            headingText="School"
          />

          <div className="services-body">
            <div className="services-col">
              <ServiceList
                title="Hospitals"
                image={data.medicalImage.sharp.fluid}
                position="30%"
                county="Clearfield"
                data={hospitals}
                headingText="Facility"
              />
            </div>
            <div className="services-col">
              <ServiceList
                title="Chambers of Commerce"
                image={data.businessImage.sharp.fluid}
                position="55%"
                county="Clearfield"
                data={chambers}
                headingText="Service"
              />
            </div>
          </div>

          <ServiceList
            title="Airports"
            image={data.airportImage.sharp.fluid}
            position="20%"
            county="Clearfield"
            data={airports}
            headingText="Airport"
            cols={2}
          />
        </section>

        <ExplorePhotos photos={photos} county="Clearfield" />
      </main>
    </>
  );
};

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "clearfield-sledriding.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "clearfield-sledriding.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

    employersImage1: file(relativePath: { eq: "APP_062.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    employersImage2: file(relativePath: { eq: "Dishroom.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    careerImage: file(relativePath: { eq: "careerlink-photo-clearfield.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    educationImage: file(
      relativePath: { eq: "neonbrand-zFSo6bnZJTw-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    postSecondary: file(
      relativePath: { eq: "clay-banks-GX8KBbVmC6c-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    medicalImage: file(relativePath: { eq: "Making-soup.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    businessImage: file(relativePath: { eq: "APP_279.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    airportImage: file(relativePath: { eq: "dubois-airport.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    # slideshow photos
    allMdx(filter: { frontmatter: { county: { eq: "clearfield" } } }) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

export default Clearfield;
